<template>
  <div class="relative">
    <img
      v-if="showRocket"
      class="circleRocketAnim__rocket absolute top-0 right-0 z-[100] h-[272px] max-w-[1150px]"
      src="@/assets/signup-rocket-loader/free-trial.gif"
    />
    <div
      v-if="showCircle"
      class="pulse absolute right-0 top-0 w-[180px] h-[180px] rounded-full bg-white border-2 border-etPink-primary flex flex-col justify-center items-center opacity-100 shadow-lg text-center"
      :class="{ 'w-[150px] h-[150px]': small }"
    >
      <TitleRegular :title="title" blue />
      <TextRegular class="whitespace-break-spaces">{{ text }}</TextRegular>
      <TextTiny class="absolute bottom-[20px]">{{ bottomNote }}</TextTiny>
    </div>
  </div>
</template>
<script setup>
import TextRegular from '@/components/shared/font/text/TextRegular'
import TextTiny from '@/components/shared/font/text/TextTiny'
import TitleRegular from '@/components/shared/font/title/TitleRegular'
import { onMounted, ref } from 'vue'

const props = defineProps({
  freeTrial: { type: Boolean, default: false },
  title: { type: String },
  text: { type: String },
  bottomNote: { type: String },
  small: { type: Boolean, default: false }
})

const showRocket = ref(true)
const showCircle = ref(false)

onMounted(() => {
  const rocket = document.querySelector('.circleRocketAnim__rocket')
  setTimeout(() => {
    showCircle.value = true
  }, 1540)
  setTimeout(() => {
    rocket.style.display = 'none'
    showRocket.value = false
  }, 1800)
})
</script>
<style lang="scss">
.pulse {
  animation: pulse 1s ease-in-out;
}
@keyframes pulse {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  20% {
    opacity: 1;
    transform: scale(1.05);
  }
  40% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
</style>
