<template>
  <div
    :id="`upgrade-subscription-card-plan-${btnTitle}`"
    class="p-6 flex flex-col rounded-lg relative cursor-pointer shadow-lg bg-white border-2 border-etBlack-50 w-full tabletLandscape:w-[30%] hover:border-etBlue-primary hover:animate-shake"
    :class="{
      'my-6 mx-0 bg-white tabletLandscape:mx-6 tabletLandscape:my-0':
        isMiddleItem,
      'bg-transparent border border-transparent cursor-notallowed hover:border-transparent':
        isCardDisabled,
      'border-2 border-etBlue-primary':
        isCurrentSub &&
        route.name == 'clientUsage' &&
        route.params.upgradeUsage != 'true',
      'border-2 border-etBlue-primary': focused,
      'tabletLandscape:w-[36%] tabletLandscape:h-[34rem] tabletLandscape:my-0 tabletLandscape:mx-[2%]':
        subscription.plan == 'scaleup' && !usagePage
    }"
    @click="$emit('setFocus', props.subscription)"
  >
    <div class="flex flex-col items-center">
      <InfoTag :text="subscriptionTag" />
      <br />
      <TitleRegular class="text-center" :title="subscription.title" />
      <TitleLarge
        blue
        :title="price"
        class="text-center py-2"
        :class="{ '!text-etBlack-100': isCardDisabled }"
      />
      <TextSmall>{{ billedPeriod }}</TextSmall>
    </div>
    <div class="mt-4">
      <div class="flex gap-2 items-center my-3">
        <font-awesome-icon
          icon="fa-solid fa-circle-check"
          class="text-etGreen-100"
          :class="{ '!text-etBlack-100': isCardDisabled }"
        />
        <TextTiny :class="{ '!lowercase': subscription.title != 'Enterprise' }">
          {{ words }}
        </TextTiny>
      </div>
      <div
        v-for="(feature, i) in featuresDetails"
        :key="i"
        class="flex gap-2 items-center my-3"
      >
        <font-awesome-icon
          icon="fa-solid fa-circle-check"
          class="text-etGreen-100"
          :class="{
            invisible: !feature.available,
            '!text-etBlack-100': isCardDisabled
          }"
        />
        <TextTiny :class="{ 'text-etBlack-50': !feature.available }">
          {{ feature.title }}
        </TextTiny>
      </div>
    </div>
    <BtnsWrapper
      :id="`upgrade-button-group-plan-${btnTitle
        .replace(' ', '-')
        .toLowerCase()}-${subscription.title.replace(' ', '-').toLowerCase()}`"
      class="flex justify-center w-full"
    >
      <BtnPrimary
        v-if="plan == 'enterprise'"
        class="w-full"
        :title="$t('shared.label.btn.reach_upgrade')"
        @click="openMessenger"
      />
      <BtnPrimary
        v-else
        data-cy="upgrade_button"
        :id="`upgrade-button-plan-${btnTitle
          .replace(' ', '-')
          .toLowerCase()}-${subscription.title
          .replace(' ', '-')
          .toLowerCase()}`"
        :title="btnTitle"
        :disabled="disabled || isCardDisabled"
        :reversed="lightButtons"
        class="w-full"
        :class="{
          'bg-etBlack-100 cursor-not-allowed hover:bg-etBlack-100':
            isCardDisabled,
          'border-2 border-etBlue-primary': lightButtons,
          'bg-etBlue-30 border-2 border-etBlue-primary':
            isCurrentSub && lightButtons
        }"
        @click="handleClick(btnTitle)"
      />
    </BtnsWrapper>
  </div>
</template>
<script setup>
import BtnPrimary from '@/components/shared/btn/BtnPrimary'
import BtnsWrapper from '@/components/shared/btn/BtnsWrapper'
import TextSmall from '@/components/shared/font/text/TextSmall'
import TextTiny from '@/components/shared/font/text/TextTiny'
import TitleLarge from '@/components/shared/font/title/TitleLarge'
import TitleRegular from '@/components/shared/font/title/TitleRegular'
import InfoTag from '@/components/tags/InfoTag.vue'
import { formatNumber } from '@/helpers/format'
import i18n from '@/i18n'
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

const route = useRoute()
const router = useRouter()
const store = useStore()
const $t = i18n.t

const emit = defineEmits(['selectedSub', 'setFocus'])

const props = defineProps({
  focused: {
    type: Boolean,
    default: false
  },
  isMiddleItem: {
    type: Boolean,
    default: false
  },
  isDisabled: {
    type: Boolean,
    default: false
  },
  lightButtons: {
    type: Boolean,
    default: false
  },
  highlightCurrentSub: {
    type: Boolean,
    default: false
  },
  usagePage: { type: Boolean, default: false },
  period: String,
  subscription: Object
})

const features = ref([
  {
    title: $t('customer.translation_assets.translation_memory.title'),
    identifier: 'tm',
    available: true
  },
  {
    title: $t('customer.upgrade_sub.feature.caas'),
    identifier: 'caas',
    available: false
  },
  {
    title: $t('customer.upgrade_sub.feature.optimisation'),
    identifier: 'optimisation',
    available: false
  },
  // {
  //   title: $t('customer.translation_assets.custom_mt.title'),
  //   identifier: 'custom_mt',
  //   available: false
  // },
  {
    title: $t('customer.upgrade_sub.feature.translator_recruitment'),
    identifier: 'translator_recruitment',
    available: false
  },
  {
    title: $t('customer.upgrade_sub.feature.customer_support'),
    identifier: 'customer_support',
    available: false
  },
  {
    title: $t('customer.upgrade_sub.feature.technical_support'),
    identifier: 'technical_support',
    available: false
  }
])

const subscriptionTag = computed(() => {
  switch (props.subscription.plan) {
    case 'startup':
      return $t('customer.upgrade_sub.startup_banner')
    case 'scaleup':
      return $t('customer.upgrade_sub.scaleup_banner')
    case 'enterprise':
      return $t('customer.upgrade_sub.enterprise_banner')
    default:
      return $t('customer.upgrade_sub.startup_banner')
  }
})
const account = computed(() => store.state.account.account)
const currentProject = computed(() => store.state.project.currentProject)
const plan = computed(() => {
  return account.value.attributes.subscription.attributes.plan_identifier
})
const subPeriod = computed(() => {
  const currentPeriod = account.value.attributes.subscription.attributes.pricing
  if (currentPeriod == 'yearly') return 'annually'
  return 'monthly'
})
const btnTitle = computed(() => {
  let btnTitle =
    props.period !== 'annually'
      ? $t('customer.upgrade_sub.upgrade')
      : $t('customer.upgrade_sub.start_trial')
  if (plan.value == 'enterprise' && props.subscription.plan != plan.value) {
    btnTitle = $t('customer.upgrade_sub.contact_sales')
  }
  if (plan.value == 'scaleup' && props.subscription.plan != plan.value) {
    if (props.subscription.plan == 'startup') {
      btnTitle = $t('customer.upgrade_sub.contact_sales')
    } else {
      btnTitle = $t('customer.upgrade_sub.upgrade')
    }
  }
  if (plan.value == 'startup' && props.subscription.plan != plan.value)
    btnTitle = $t('customer.upgrade_sub.upgrade')
  if (plan.value == props.subscription.plan) {
    btnTitle = $t('customer.upgrade_sub.current_plan')
    if (props.period == 'monthly' && subPeriod.value != props.period) {
      btnTitle = $t('customer.upgrade_sub.contact_sales')
    }
    if (subPeriod.value == 'monthly' && subPeriod.value != props.period) {
      btnTitle = $t('customer.upgrade_sub.upgrade')
    }
  }
  if (props.subscription.plan == 'enterprise') {
    btnTitle = $t('customer.upgrade_sub.contact_sales')
  }
  return btnTitle
})
const disabled = computed(() => {
  if (
    props.subscription.plan == plan.value &&
    subPeriod.value == props.period &&
    btnTitle.value == $t('customer.upgrade_sub.current_plan')
  )
    return true
  return false
})
const billedPeriod = computed(() => {
  if (props.period == 'annually')
    return $t('customer.upgrade_sub.billed_annually')
  return $t('customer.upgrade_sub.billed_monthly')
})
const price = computed(() => {
  if (props.period == 'annually')
    return props.subscription.title != 'Enterprise'
      ? props.subscription.annually + '€/' + $t('customer.upgrade_sub.month')
      : props.subscription.annually
  return props.subscription.title != 'Enterprise'
    ? props.subscription.monthly + '€/' + $t('customer.upgrade_sub.month')
    : props.subscription.monthly
})
const words = computed(() => {
  if (props.subscription.title != 'Enterprise')
    return `${formatNumber(
      props.subscription.words / (props.period === 'annually' ? 1 : 12)
    )} ${$t('shared.label.words')}${
      props.period === 'annually' ? '' : `/${$t('customer.upgrade_sub.month')}`
    }`
  return $t('customer.upgrade_sub.feature.custom_words')
})
const featuresDetails = computed(() => {
  if (props.subscription.title == 'Enterprise') {
    features.value.forEach((feature) => {
      feature.available = true
    })
    return features.value
  } else if (props.subscription.title == 'Scaleup') {
    features.value.forEach((feature) => {
      if (
        feature.identifier == 'custom_mt' ||
        feature.identifier == 'optimisation' ||
        feature.identifier == 'caas'
      ) {
        feature.available = true
      }
    })
    return features.value
  } else {
    return features.value
  }
})
const isCardDisabled = computed(() => {
  if (route.name == 'clientProject' && route.params.upgradeUsage != 'true') {
    if (
      currentProject?.value?.attributes?.words_count > props.subscription.words
    )
      return true
  }
  return false
})

const isCurrentSub = computed(() => {
  return (
    plan.value == props.subscription.plan && subPeriod.value == props.period
  )
})
function openMessenger() {
  if (route.hash !== '#hs-chat-open') {
    router.push({ ...route, hash: '#hs-chat-open' })
  }
}

function handleClick(title) {
  if (title === $t('customer.upgrade_sub.contact_sales')) {
    window.location.href =
      'https://meetings.hubspot.com/lh21/sales-round-robin-'
  } else {
    emit('selectedSub', [props.subscription, props.period])
    router.replace({
      query: {
        ...route.query,
        upgradeSubscriptionPaymentInfo: true
      }
    })
  }
}
</script>
