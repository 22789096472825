<template>
  <section>
    <div
      class="fixed block top-0 left-0 w-full h-full z-[9] invisible transition-all duration-300 desktop:hidden"
      :class="{ '!visible': showSidebar }"
    >
      <Overlay :is-active="showSidebar" @click="toggleSidebar(false)" />
      <div
        id="scrollSidebarTarget"
        class="overflow-y-auto scroll-smooth flex flex-col p-4 relative left-0 top-0 h-full max-w-[300px] w-[calc(100%-70px)] bg-white transform transition-transform duration-300 ease-in-out will-change-transform"
        :class="showSidebar ? 'translate-x-0' : 'translate-x-[-100%]'"
      >
        <SidebarHeader />
        <SidebarBody />
      </div>
    </div>
  </section>
</template>
<script setup>
import Overlay from '@/components/sidebar/Overlay'
import SidebarBody from '@/components/sidebar/SidebarBody'
import SidebarHeader from '@/components/sidebar/SidebarHeader'
import { computed } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const showSidebar = computed(() => store.state.navigation.showSidebar)
const toggleSidebar = (payload) =>
  store.commit('navigation/toggleSidebar', payload)
</script>
