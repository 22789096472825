<template>
  <li class="py-3 px-5">
    <TextSmall bold blue class="underline" @click="$emit('clickTitle')">{{
      title
    }}</TextSmall>
    <div class="flex flex-col gap-2 mt-1" @click="toggleSidebar(false)">
      <router-link
        v-for="(item, i) in list"
        :key="item.title + i"
        :to="{ name: item.route }"
      >
        <TextSmall blue class="subnav__item__link--text">{{ $t(item.title) }}</TextSmall blue>
      </router-link>
    </div>
  </li>
</template>
<script setup>
import TextSmall from '@/components/shared/font/text/TextSmall'
import { useStore } from 'vuex'
const store = useStore()
const emit = defineEmits(['clickTitle'])
const props = defineProps({
  title: String,
  list: Array
})
const toggleSidebar = (payload) =>
  store.commit('navigation/toggleSidebar', payload)
</script>
