<template>
  <div>
    <router-link :to="{ name: 'clientOverview' }">
      <Logo @click="toggleSidebar(false)" />
    </router-link>
    <div class="flex gap-4 justify-start">
      <CreateProjectButton v-if="!isAdmin" @click="toggleSidebar(false)" />
      <CreateFolderButton v-if="!isAdmin" />
      <IconMessages is-customer />
    </div>
  </div>
</template>
<script setup>
import CreateFolderButton from '@/components/shared/CreateFolderButton'
import CreateProjectButton from '@/components/shared/CreateProjectButton'
import IconMessages from '@/components/shared/IconMessages'
import Logo from '@/components/sidebar/Logo'
import { computed } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const isAdmin = computed(() => store.getters['account/isAdmin'])
const toggleSidebar = (payload) =>
  store.commit('navigation/toggleSidebar', payload)
</script>
